// Page wrapper
.page-wrapper {
  display: block;
  margin: 4px auto;
  max-width: 98%;

  .page-image {
    float: left;
    margin-right: 28px;
  }

  .page-content {
    width: 100%;
  }
}

.home {
  .page-body {
    display: inline-block;
    width: 60%;
  }

  .contact {
    border: 2px solid #000;
    display: inline-block;
    float: right;
    height: 200px;
    width: 200px;
    .inner {
      border: 1px solid black;
      margin: 2px;
      min-height: 181px;
      text-align: center;
      padding: 6px;
    }
  }
}

/**
 * Site header
 */
.site-header {
  ul.contact { display: none; }
  color: $header-text-color;
  margin: 14px 12px 0 12px;
  min-height: 120px;
  text-align: center;

  background-color: $header-color-dark;

  -webkit-border-top-left-radius: $header-border-radius;
  -webkit-border-top-right-radius: $header-border-radius;
  -moz-border-radius-topleft: $header-border-radius;
  -moz-border-radius-topright: $header-border-radius;
  border-top-left-radius: $header-border-radius;
  border-top-right-radius: $header-border-radius;

  padding: 22px;

  .header-telephone {
    width: 100%;
    text-align: center;
    font-family: serif;
    color: #fff;

    a {
      color: $navigation-text-color;
    }
  }

  .header-box {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto 12px;

    .est {
      flex-grow: 1;
      font-family:serif;
    }

    .logo {
      flex-grow: 4;
      font-size: 3vw;
      padding-top: 18px;
      text-transform: uppercase;

      .site-title {
        margin: 22px auto 6px auto;
        font-family: Times New Roman, serif;
        color: $header-text-color;
        letter-spacing: -1px;
        text-decoration: none;

        &,
        &:visited {
          color: $header-text-color;
        }
      }
    }

    .hetas-approved {
      flex-grow: 2;
      margin-top: 18px;

      a {
        background: url('/images/hetas-logo.png') no-repeat;
        display: inline-block;
        height: 60px;
        text-indent: -10000px;
        width: 130px;
      }
    }
  }

  .telephone {
    margin: 2px auto;
    text-align: center;
  }

  .site-nav {
    font-size: 18px;
    margin-top: 12px;

    ul {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        flex-grow: 1;
      }
    }

    .page-link {
      color: $navigation-text-color;
      line-height: $base-line-height;
      font-weight: bold;
    }
  }
}

.offers {
  font-family: Times New Roman, serif;
  font-size: 1.4em;
  line-height: 1.8;
  color: #000;
  width: 100%;
  min-height: 86px;
  background: linear-gradient(to right, #f67402, #ffcf00);
  margin: 23px auto 3px auto;
  text-align: center;

  .summer-deal {
    background: url('/images/summer-deal.png') no-repeat;
    display: inline-block;
    float: left;
    height: 176px;
    position: relative;
    text-indent: -10000px;
    top: -85px;
    width: 216px;
  }
}


/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    display: flex;
    padding: $spacing-unit 0;

    ul {
      list-style: none;
      li {
        float: left;
        margin-right: 10px;
      }
    }
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}


body {
}
/**
 * Page content
 */
.page-content {
//    max-width: $page-max-width;
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.testimonials {
  ul {
    list-style-type: none;
    .testimonial {
      margin-bottom: 26px;
      color: #333;

      .testimonial-body {
        font-style: italic;
      }
      .testimonial-author {
        font-weight: bold;
      }
    }
  }
}

/**
 * Recent installations
 *
 */
.gallery-wrapper {
  margin: 4px auto 4px auto;
  text-align:center;
  .thumbnail-wrapper {
    text-align: center;
    margin: 4px;
    padding: 2px;
    display: inline-block;
    height: 125px;
    width: 130px;

    a {
      display: block;
      height: 125px;
      overflow: hidden;
      width: 125px;

      img {
        @include vertical-align;
        margin: 2px;
        max-height: 250px;
        max-width: 250px;
      }
    }
  }
}

@media all and (max-width: 882px) {
  .site-header {
    .est, .header-telephone { display: none; }

    .logo {
      .site-title {
        font-size: 7vw;
      }
    }

    .site-nav {
      ul {
        li {
          flex-basis: 100%;
        }
      }
    }

    ul.contact {
      display: inline-block;
      list-style-type: none;
      margin: 6px auto;

      li {
        a {
          color: #fff;
          font-size: 26px;
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }
  }

  .home {
    .page-body { width: 99%; }
    .contact { display: none }
  }

  .page-image { display: none }

  .offers {
    .summer-deal {
      display: none;
    }
  }
}
